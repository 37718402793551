import React, {Component} from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Pages
import Home from './pages/Home/Home.js';
import SignUp from './pages/Sign-Up/SignUp.js';
import SignIn from './pages/Sign-In/SignIn.js';
import Months from './pages/Months/Months.js';
import Videos from './pages/Videos/Videos.js';
import Gallery from './pages/Gallery/Gallery.js';
import FreeVideos from './pages/FreeVideos/FreeVideos.js';
import Profile from './pages/Profile/Profile.js';
import Exams from './pages/Exams/Exams.js';
import SingleExam from './pages/Exams/SingleExam.js';
import ViewExam from './pages/Exams/ViewAnswers.js';

class Connector extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/sign-up">
            <SignUp />
          </Route>
          <Route path="/sign-in">
            <SignIn />
          </Route>
          <Route path="/months" exact>
            <Months />
          </Route>
          <Route path="/free-videos/:id?" component={FreeVideos} />
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/months/:id" component={Videos} />
          <Route path="/gallery">
            <Gallery />
          </Route>
          <Route path="/exams" exact>
            <Exams />
          </Route>
          <Route path="/exams/:id" component={SingleExam} exact />
          <Route path="/exams/view/:id" component={ViewExam} />
        </Switch>
      </Router>
    );
  }
}

export default Connector;
